import React, { useState, useEffect } from 'react';
import { List, Avatar, Card, Typography, Row, Col, Badge, Divider, Tag } from 'antd';
import socket from '../../socket';
import { fetchOnlineUsers, getUsers } from '../../services/apiMethods';
import moment from 'moment';

const { Text, Title } = Typography;

const OnlineUsersList = ({ user }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const online = await fetchOnlineUsers();
        const all = await getUsers();

        // Фильтрация по команде
        const filteredAllUsers =
          user?.role === 'admin' || !user?.team
            ? all
            : all.filter((u) => u.team === user.team || !u.team);

        const filteredOnlineUsers =
          user?.role === 'admin' || !user?.team
            ? online
            : online.filter((u) => u.team === user.team || !u.team);

        // Если текущий пользователь онлайн, добавляем его в список
        if (!filteredOnlineUsers.some((u) => u.id === user.id)) {
          filteredOnlineUsers.push({
            id: user.id,
            username: user.username,
            role: user.role,
            team: user.team,
            online: true,
            sessionStart: moment().subtract(1, 'minute').toISOString(), // Пример начальной сессии
          });
        }

        setOnlineUsers(filteredOnlineUsers);
        setAllUsers(filteredAllUsers.sort((a, b) => moment(b.lastOnline).diff(moment(a.lastOnline))));
      } catch (error) {
        console.error('Ошибка загрузки пользователей:', error);
      }
    };

    loadUsers();

    const handleUpdateOnlineUsers = async (userIds) => {
      try {
        const updatedUsers = await getUsers({ ids: userIds });
        const filteredUsers = updatedUsers.filter(
          (u) => !u.team || u.team === user.team || user.role === 'admin'
        );
        setOnlineUsers(filteredUsers);
      } catch (error) {
        console.error('Ошибка обновления списка онлайн-пользователей:', error);
      }
    };

    socket.on('updateOnlineUsers', handleUpdateOnlineUsers);

    return () => {
      socket.off('updateOnlineUsers', handleUpdateOnlineUsers);
    };
  }, [user]);

  const formatDuration = (startTime) => {
    const duration = moment.duration(moment().diff(moment(startTime)));
    return `${duration.hours()}ч ${duration.minutes()}м`;
  };

  return (
    <Card bordered={false} style={{ borderRadius: 8, padding: '20px' }}>
      <Title level={4}>Онлайн пользователи</Title>
      <Divider />

      {onlineUsers.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Text type="secondary">Нет пользователей онлайн</Text>
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {onlineUsers.map((user) => (
            <Col key={user.id} xs={24} sm={12} lg={8}>
              <Badge.Ribbon text="Онлайн" color="green">
                <Card hoverable bordered style={{ borderRadius: 8, textAlign: 'center' }}>
                  <Avatar size={48} style={{ backgroundColor: '#87d068', marginBottom: '8px' }}>
                    {user.username.charAt(0).toUpperCase()}
                  </Avatar>
                  <Title level={5} style={{ margin: 0 }}>
                    {user.username}
                  </Title>
                  <Tag color="blue" style={{ marginTop: 8 }}>
                    {user.role}
                  </Tag>
                  <Text type="secondary" style={{ display: 'block', marginTop: 4 }}>
                    {user.team || 'Нет команды'}
                  </Text>
                  <Text type="secondary" style={{ display: 'block', marginTop: 4 }}>
                    В сети: {formatDuration(user.sessionStart)}
                  </Text>
                </Card>
              </Badge.Ribbon>
            </Col>
          ))}
        </Row>
      )}

      <Divider />

      <Title level={4}>История пользователей</Title>
      <List
        dataSource={allUsers}
        renderItem={(user) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar size="large" style={{ backgroundColor: user.online ? '#87d068' : '#ccc' }}>
                  {user.username.charAt(0).toUpperCase()}
                </Avatar>
              }
              title={
                <span>
                  {user.username}{' '}
                  {user.online && <Tag color="green">Онлайн</Tag>}
                </span>
              }
              description={
                <div>
                  <Text type="secondary">Роль: {user.role}</Text>
                  <br />
                  <Text type="secondary">Команда: {user.team || 'Нет команды'}</Text>
                  <br />
                  <Text type="secondary">
                    Последний раз онлайн:{' '}
                    {user.lastOnline
                      ? moment(user.lastOnline).format('DD.MM.YYYY HH:mm')
                      : 'Неизвестно'}
                  </Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default OnlineUsersList;
