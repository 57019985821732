import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Modal,
	Form,
	Input,
	message,
	Select,
	Popconfirm,
} from "antd";
import {
	getTeams,
	getUsersInTeam,
	updateUserProfile,
	deleteUser, // метод удаления пользователя
	addNewTeam, // метод создания новой команды
} from "../../services/apiMethods";

const { Option } = Select;

const TeamManagement = () => {
	const [teams, setTeams] = useState([]);
	const [users, setUsers] = useState([]);
	const [isUserListVisible, setIsUserListVisible] = useState(false);
	const [isUserEditModalVisible, setIsUserEditModalVisible] = useState(false);
	const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
		useState(false); // Для модалки создания команды
	const [currentUser, setCurrentUser] = useState(null);
	const [filterValue, setFilterValue] = useState("");
	const [form] = Form.useForm();
	const [teamForm] = Form.useForm(); // Форма для создания команды

	useEffect(() => {
		fetchTeams();
	}, []);

	const fetchTeams = async () => {
		try {
			const data = await getTeams();
			setTeams(data);
		} catch {
			message.error("Ошибка при загрузке команд");
		}
	};

	const fetchUsersInTeam = async (teamId) => {
		try {
			const data = await getUsersInTeam(teamId);
			setUsers(data);
			setIsUserListVisible(true);
		} catch {
			message.error("Ошибка при загрузке пользователей команды");
		}
	};

	const handleDeleteUser = async (userId) => {
		try {
			await deleteUser(userId);
			message.success("Пользователь успешно удален");
			fetchUsersInTeam(currentUser.teamId); // обновляем список пользователей
		} catch {
			message.error("Ошибка при удалении пользователя");
		}
	};

	const handleEditUser = (user) => {
		setCurrentUser(user);
		setIsUserEditModalVisible(true);
	};

	const handleUpdateUser = async (values) => {
		try {
			await updateUserProfile(currentUser.id, values);
			message.success("Профиль пользователя успешно обновлен");
			setIsUserEditModalVisible(false); // Закрываем модальное окно
			form.resetFields(); // Сбрасываем значения формы
			fetchUsersInTeam(currentUser.teamId); // Обновляем список пользователей
		} catch {
			message.error("Ошибка при обновлении профиля пользователя");
		}
	};

	const handleCreateNewTeam = async (values) => {
		try {
			await addNewTeam(values); // Вызываем метод для создания команды
			message.success("Новая команда успешно создана");
			fetchTeams(); // Обновляем список команд
			setIsCreateTeamModalVisible(false); // Закрываем модалку
			teamForm.resetFields(); // Сбрасываем форму
		} catch {
			message.error("Ошибка при создании команды");
		}
	};

	const filteredUsers = users.filter((user) =>
		user.username.toLowerCase().includes(filterValue.toLowerCase())
	);

	return (
		<div>
			<h2>Управление командами</h2>

			{/* Кнопка "Создать команду" */}
			<Button
				type="primary"
				onClick={() => setIsCreateTeamModalVisible(true)}
				style={{ marginBottom: 20 }}
			>
				Создать новую команду
			</Button>

			<Table
				dataSource={teams}
				columns={[
					{
						title: "Название команды",
						dataIndex: "name",
						key: "name",
					},
					{
						title: "Действия",
						key: "actions",
						render: (text, record) => (
							<span>
								<Button
									onClick={() => fetchUsersInTeam(record.id)}
									style={{ marginRight: 10 }}
								>
									Посмотреть пользователей
								</Button>
							</span>
						),
					},
				]}
				rowKey="id"
			/>

			{isUserListVisible && (
				<div>
					<h3>Пользователи команды</h3>
					<Input
						placeholder="Фильтр по имени пользователя"
						value={filterValue}
						onChange={(e) => setFilterValue(e.target.value)}
						style={{ marginBottom: 20 }}
					/>
					<Table
						dataSource={filteredUsers}
						columns={[
							{
								title: "Имя пользователя",
								dataIndex: "username",
								key: "username",
							},
							{
								title: "Действия",
								key: "actions",
								render: (text, record) => (
									<span>
										<Button
											onClick={() => handleEditUser(record)}
											style={{ marginRight: 10 }}
										>
											Изменить
										</Button>
										<Popconfirm
											title="Вы уверены, что хотите удалить этого пользователя?"
											onConfirm={() => handleDeleteUser(record.id)}
											okText="Да"
											cancelText="Нет"
										>
											<Button danger>Удалить</Button>
										</Popconfirm>
									</span>
								),
							},
						]}
						rowKey="id"
					/>
				</div>
			)}

			{/* Модальное окно для создания команды */}
			<Modal
				title="Создать новую команду"
				open={isCreateTeamModalVisible}
				onCancel={() => setIsCreateTeamModalVisible(false)}
				footer={null}
			>
				<Form form={teamForm} onFinish={handleCreateNewTeam}>
					<Form.Item
						name="name"
						label="Название команды"
						rules={[{ required: true, message: "Введите название команды" }]}
					>
						<Input />
					</Form.Item>
					<Button type="primary" htmlType="submit">
						Создать
					</Button>
				</Form>
			</Modal>

			{/* Модальное окно для редактирования пользователя */}
			<Modal
				title="Редактировать пользователя"
				open={isUserEditModalVisible}
				onCancel={() => {
					setIsUserEditModalVisible(false);
					form.resetFields(); // Сбрасываем значения формы при закрытии
				}}
				footer={null}
			>
				<Form
					form={form} // Связываем форму с состоянием
					onFinish={handleUpdateUser}
					initialValues={currentUser} // Устанавливаем начальные значения
				>
					<Form.Item
						name="username"
						label="Имя пользователя"
						rules={[{ required: true, message: "Введите имя пользователя" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="password"
						label="Пароль"
						rules={[{ required: true, message: "Введите пароль" }]}
					>
						<Input.Password />
					</Form.Item>
					<Form.Item name="teamId" label="Команда">
						<Select>
							{teams.map((team) => (
								<Option key={team.id} value={team.id}>
									{team.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form>
			</Modal>
		</div>
	);
};

export default TeamManagement;
