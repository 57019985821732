import apiService from './apiService';

const authService = {
  login: async (credentials) => {
    try {
      const response = await apiService.post('/login', credentials);
      const { token, ...userData } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userData));

      return userData;
    } catch (error) {
      console.error('Login Error:', error.response || error);
      
      // Проверяем, связана ли ошибка с IP-адресом
      if (error.response?.status === 403 && error.response?.data?.error.includes('IP-адрес')) {
        throw new Error(`Ошибка авторизации: ${error.response.data.error}. Ваш IP: ${error.response.data.ip}`);
      }

      throw error;
    }
  },

  getCurrentUserFromToken: async () => {
    try {
      const response = await apiService.get('/auth/current-user');
      return response.data;
    } catch (error) {
      console.error('Get Current User Error:', error.response || error); // Логируем ошибку
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  getCurrentUser2: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },

  // Метод для получения текущего пользователя
  getCurrentUser: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },

  // Новый метод для проверки, аутентифицирован ли пользователь
  isAuthenticated: () => {
    const token = localStorage.getItem('token');
    return token ? true : false;  // Если токен есть, возвращаем true, иначе false
  }
};

export default authService;
