import React from 'react';
import EmployeeDashboard from '../components/Employee/EmployeeDashboard.js';

import LeadList from '../components/Shared/LeadList.js';

const EmployeePage = ({user}) => {
  return (
    <div>
      <EmployeeDashboard />
      <LeadList user={user}/>
    </div>
  );
};

export default EmployeePage;
