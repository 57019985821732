import React from 'react';
import { Table } from 'antd';



const EmployeeDashboard = () => {
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Lead Info',
      dataIndex: 'leadInfo',
      key: 'leadInfo',
    },
  ];

  const dataSource = [
    {
      key: '1',
      status: 'Closed',
      leadInfo: 'Client Info #1',
    },
    {
      key: '2',
      status: 'Pending',
      leadInfo: 'Client Info #2',
    },
  ];

  return (
    <div>
      <h1>Employee Dashboard</h1>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default EmployeeDashboard;
