import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { getCurrentUser, updateUserProfile } from '../../services/apiMethods';

const UserProfile = ({ user }) => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user && user.username) {
      fetchUserData();
    }
  }, [user]);

  const fetchUserData = async () => {
    try {
      const data = await getCurrentUser(user.username);
      setUserData(data);
      form.setFieldsValue({
        username: data.username,
        role: data.role,
        teamId: data.teamId || 'Нет команды',
        createdAt: data.createdAt ? new Date(data.createdAt).toLocaleDateString() : 'Не указана',
      });
    } catch {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось загрузить данные пользователя.',
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        id: userData.id, // Передаем ID вместо username
      };

      const updatedData = await updateUserProfile(payload);
      notification.success({
        message: 'Успех',
        description: 'Данные пользователя успешно обновлены.',
      });
      setUserData(updatedData);
    } catch {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось обновить данные пользователя.',
      });
    }
  };

  return (
    <div>
      <h2>Профиль пользователя</h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ maxWidth: 400 }}
      >
        <Form.Item
          name="username"
          label="Имя пользователя"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item name="role" label="Роль">
          <Input disabled />
        </Form.Item>

        <Form.Item name="teamId" label="Команда">
          <Input disabled />
        </Form.Item>

        <Form.Item name="createdAt" label="Дата регистрации">
          <Input disabled />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form>
    </div>
  );
};

export default UserProfile;