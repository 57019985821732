import React, { useState, useEffect } from 'react';
import { Row, Col, message, Card, Button } from 'antd';
import UserForm from '../components/Admin/UserForm';
import UserTable from '../components/Admin/UserTable';
import { getUsers, addUser, deleteUser, getTeams, assignLeadsToAll } from '../services/apiMethods'; // Импортируем метод assignLeadsToAll

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]); // Состояние для списка команд
  const [isLoading, setIsLoading] = useState(true); // Флаг загрузки данных
  const [isAssigningLeads, setIsAssigningLeads] = useState(false); // Флаг загрузки при назначении лидов

  useEffect(() => {
    fetchUsers(); // Загрузка пользователей при монтировании компонента
    fetchTeams(); // Загрузка команд
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true); // Устанавливаем флаг загрузки
      const response = await getUsers(); // Получаем всех пользователей
      setUsers(response); // Устанавливаем пользователей
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
      message.error('Ошибка при загрузке пользователей');
    } finally {
      setIsLoading(false); // Снимаем флаг загрузки
    }
  };

  const fetchTeams = async () => {
    try {
      setIsLoading(true);
      const response = await getTeams(); // Получаем список всех команд
      setTeams(response); // Устанавливаем список команд
    } catch (error) {
      console.error('Ошибка при загрузке команд:', error);
      message.error('Ошибка при загрузке команд');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async (newUser) => {
    try {
      await addUser(newUser); // Добавляем нового пользователя
      message.success('Пользователь успешно добавлен');
      fetchUsers(); // Перезагрузка списка пользователей
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      message.error('Ошибка при добавлении пользователя');
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id); // Удаляем пользователя
      message.success('Пользователь успешно удален');
      fetchUsers(); // Перезагрузка списка пользователей
    } catch (error) {
      console.error('Ошибка при удалении пользователя:', error);
      message.error('Ошибка при удалении пользователя');
    }
  };

  // Функция для назначения лидов всем пользователям всех команд
  const handleAssignLeadsToAll = async () => {
    try {
      setIsAssigningLeads(true); // Устанавливаем флаг загрузки
      await assignLeadsToAll(); // Отправляем запрос на сервер для назначения лидов всем пользователям всех команд
      message.success('Лиды успешно назначены всем пользователям всех команд');
      fetchUsers(); // Перезагружаем список пользователей
    } catch (error) {
      console.error('Ошибка при назначении лидов:', error);
      message.error('Ошибка при назначении лидов');
    } finally {
      setIsAssigningLeads(false); // Снимаем флаг загрузки
    }
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h1>Панель Администратора</h1>
      <Row gutter={16} style={{ marginTop: '40px' }} justify="center">
        <Col span={24}>
          <Card title="Добавить пользователя" style={{ marginBottom: '20px' }}>
            <UserForm onAddUser={handleAddUser} />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Список пользователей">
            <UserTable
              initialUsers={users} // Передаем список пользователей в таблицу
              onDeleteUser={handleDeleteUser} // Передаем обработчик удаления
              isLoading={isLoading} // Передаем флаг загрузки
            />
          </Card>
        </Col>

        {/* Добавляем кнопку для назначения лидов всем пользователям всех команд */}
        <Col span={24} style={{ marginTop: '20px' }}>
          <Button
            type="primary"
            onClick={handleAssignLeadsToAll}
            loading={isAssigningLeads} // Флаг загрузки
          >
            Назначить лидов всем пользователям всех команд
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPage;
