import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Modal,
	Form,
	Input,
	Select,
	DatePicker,
	message,
	Switch,
} from "antd";
import moment from "moment";
import {
	getLeads,
	getNextLead,
	updateLead,
	updateLeadActivity,
	getUsers,
	getTeams,
} from "../../services/apiMethods";

const { Option } = Select;

const statusMap = [
	{ id: 1, name: "Новый" },
	{ id: 2, name: "Ребенок" },
	{ id: 3, name: "НБТ" },
	{ id: 4, name: "Нет средств" },
	{ id: 5, name: "Умник" },
	{ id: 6, name: "Срез" },
	{ id: 7, name: "Перезвон" },
	{ id: 8, name: "Поляк" },
	{ id: 9, name: "Списали" },
	{ id: 10, name: "Срез на закрытии" },
	{ id: 11, name: "Закрыт" },
];

const LeadList = ({ user }) => {
	useEffect(() => {
		const loadInitialData = async () => {
			await fetchTeamsData(); // Загружаем команды
			await fetchLeads(); // Загружаем лиды после получения команд
			await fetchEmployeeOptions(); // Загружаем сотрудников
		};
		loadInitialData();
	}, [user]);

	const [leads, setLeads] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentLead, setCurrentLead] = useState(null);
	const [employeeOptions, setEmployeeOptions] = useState([]);
	const [teamOptions, setTeamOptions] = useState([]);
	const [isFetchingNextLead, setIsFetchingNextLead] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	}); // Управление пагинацией
	const [filters, setFilters] = useState({}); // Фильтры
	const [sorter, setSorter] = useState({}); // Сортировка

	useEffect(() => {
		const loadInitialData = async () => {
			await fetchTeamsData(); // Загружаем команды
			await fetchEmployeeOptions(); // Загружаем сотрудников
		};
		loadInitialData();
	}, [user]); // Выполняется при изменении пользователя

	// Этот useEffect сработает, когда команды будут загружены
	useEffect(() => {
		if (teamOptions.length > 0 && employeeOptions.length > 0) {
			fetchLeads(); // Загружаем лиды только после загрузки команд и сотрудников
		}
	}, [teamOptions, employeeOptions]);

	const fetchLeads = async (
		paginationParams = pagination,
		filterParams = filters,
		sortParams = sorter
	) => {
		setIsLoading(true);
		try {
			const sortField = sortParams.field || "updatedAt";
			const sortOrder = sortParams.order === "ascend" ? "ASC" : "DESC";

			const preparedFilters = prepareFilters(filterParams);

			const params = {
				role: user.role,
				...(user.role === "employee" && { assignedTo: user.username }),
				...(user.role === "closer" && { team: user.team }),
				page: paginationParams.current,
				pageSize: paginationParams.pageSize,
				sortField,
				sortOrder,
				...preparedFilters,
			};

			// console.log("Параметры для запроса:", params);

			const data = await getLeads(params);

			setLeads(data.leads); // Обновляем таблицу
			setPagination({
				current: data.page,
				pageSize: data.pageSize,
				total: data.total,
			});
		} catch (error) {
			console.error("Ошибка загрузки лидов:", error);
			message.error(error.response?.data?.error || "Ошибка загрузки лидов");
		} finally {
			setIsLoading(false);
		}
	};

	const fetchTeamsData = async () => {
		if (teamOptions.length > 0) return; // Избегаем повторного запроса
		try {
			const teams = await getTeams();
			setTeamOptions(teams);
		} catch {
			message.error("Ошибка при загрузке команд");
		}
	};

	const fetchEmployeeOptions = async () => {
		if (employeeOptions.length > 0) return; // Избегаем повторного запроса
		try {
			const users = await getUsers({ role: "employee" });
			setEmployeeOptions(users);
		} catch {
			message.error("Ошибка при загрузке сотрудников");
		}
	};

	const fetchNextLead = async () => {
		if (isFetchingNextLead) return; // Защита от параллельных вызовов
		setIsFetchingNextLead(true);

		try {
			const data = await getNextLead(user.username);

			if (data) {
				message.success("Назначен новый лид.");
				setLeads((prevLeads) => [...prevLeads, data]);
			} else {
				message.info("Нет доступных новых лидов.");
			}
		} catch (error) {
			console.error("Ошибка при получении нового лида:", error);
			message.error("Ошибка при получении нового лида.");
		} finally {
			setIsFetchingNextLead(false);
		}
	};

	const handleUpdate = async (values) => {
		if (isSubmitting) return;
		setIsSubmitting(true);

		try {
			const isStatusChangedFromNew =
				currentLead.statusId === 1 && values.status !== 1;

			const payload = {
				...values,
				statusId: values.status,
				team: values.team,
			};

			await updateLead(currentLead.id, payload);

			message.success("Лид успешно обновлен.");
			await fetchLeads();

			if (isStatusChangedFromNew && user.role === "employee") {
				await fetchNextLead(); // Защищённый вызов
			}

			setIsModalVisible(false);
			form.resetFields();
		} catch (error) {
			console.error("Ошибка при обновлении лида:", error);
			message.error("Ошибка при обновлении лида.");
		} finally {
			setIsSubmitting(false);
		}
	};

	const prepareFilters = (filters) => {
		const result = {};

		// Обрабатываем диапазон дат
		if (filters.updatedAt) {
			const dateRange = filters.updatedAt.split(",");
			if (dateRange.length === 2) {
				result.startDate = dateRange[0];
				result.endDate = dateRange[1];
			}
		}

		// Обрабатываем фильтр по статусу
		if (filters.statusId) {
			result.status = Array.isArray(filters.statusId)
				? filters.statusId.map(Number) // Преобразуем в числа
				: [Number(filters.statusId)];
		}

		// Обрабатываем фильтр по команде
		if (filters.team) {
			result.team = filters.team; // Команда
		}

		// Прочие фильтры (например, комментарий)
		Object.keys(filters).forEach((key) => {
			if (!["updatedAt", "statusId", "team"].includes(key) && filters[key]) {
				result[key] = filters[key];
			}
		});

		return result;
	};

	const showEditModal = (lead) => {
		setCurrentLead(lead);
		form.setFieldsValue({
			...lead,
			status: lead.statusId,
			team: lead.team, // Устанавливаем название команды
			dateOfBirth: lead.dateOfBirth ? moment(lead.dateOfBirth) : null,
		});
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const handleLeadActivityChange = async (leadId, newStatus) => {
		setIsLoading(true);
		try {
			await updateLeadActivity(leadId, newStatus);
			fetchLeads();
			message.success("Статус активности лида обновлен.");
		} catch (error) {
			message.error("Ошибка при изменении статуса активности.");
		} finally {
			setIsLoading(false);
		}
	};

	const getColumnSelectProps = (dataIndex, options) => ({
		filters: options.map((option) => ({
			text: option.name || option.username || option,
			value: option.id || option.username || option,
		})),
		onFilter: (value, record) => record[dataIndex] === value,
	});

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`Поиск ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={confirm}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={confirm}
					style={{ width: "100%", marginBottom: 8 }}
				>
					Поиск
				</Button>
				<Button onClick={clearFilters} style={{ width: "100%" }}>
					Сброс
				</Button>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
	});

	const getColumnFilterProps = (dataIndex, options) => ({
		filters: options.map((option) => ({
			text: option.name || option,
			value: option.id || option,
		})),
		onFilter: (value, record) => record[dataIndex] === value,
	});

	const countryFlags = {
		Польша: "🇵🇱",
		США: "🇺🇸",
		Германия: "🇩🇪",
		Франция: "🇫🇷",
		// Добавьте другие страны при необходимости
	};

	const columns = [
		{
			title: "Имя",
			dataIndex: "name",
			key: "name",
			sorter: true,
			...getColumnSearchProps("name"),
		},
		{
			title: "Телефон",
			dataIndex: "phone",
			key: "phone",
			render: (phone) => (
				<a href={`sip:${phone}`} target="_blank" rel="noopener noreferrer">
					{phone}
				</a>
			),
			...getColumnSearchProps("phone"),
		},
		{
			title: "Команда",
			dataIndex: "team",
			key: "team",
			...getColumnSelectProps(
				"team",
				teamOptions.map((t) => ({ id: t.name, name: t.name }))
			),
		},
		{
			title: "Статус",
			dataIndex: "statusId",
			key: "status",
			render: (statusId) => {
				const status = statusMap.find((status) => status.id === statusId);
				return status ? status.name : "Не указан";
			},
			...getColumnSelectProps("statusId", statusMap),
		},
		{
			title: "Ответственный",
			dataIndex: "assignedTo",
			key: "assignedTo",
			render: (assignedTo) => assignedTo || "Не назначен",
			...getColumnSearchProps("assignedTo"),
		},
		{
			title: "Страна",
			dataIndex: "country",
			key: "country",
			render: (country) =>
				countryFlags[country] ? (
					<span>{countryFlags[country]}</span>
				) : (
					<span>{country || "Не указано"}</span>
				),
		},
		{
			title: "Источник",
			dataIndex: "source",
			key: "source",
			render: (source) => source || "Не указано",
			...getColumnFilterProps(
				"source",
				Array.from(
					new Set(leads.map((lead) => lead.source || "Не указано"))
				).map((source) => ({
					name: source,
					id: source,
				}))
			),
		},
		{
			title: "Дата обновления",
			dataIndex: "updatedAt",
			key: "updatedAt",
			sorter: true,
			render: (text) => moment(text).format("DD.MM.YYYY HH:mm"),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<DatePicker.RangePicker
						format="DD.MM.YYYY"
						onChange={(dates) => {
							if (dates) {
								const formattedDates = dates.map((date) =>
									date.format("YYYY-MM-DD")
								);
								setSelectedKeys([formattedDates.join(",")]);
							} else {
								setSelectedKeys([]);
							}
						}}
						style={{ marginBottom: 8 }}
					/>
					<Button
						type="primary"
						onClick={() => {
							confirm();
							fetchLeads(
								pagination,
								{ ...filters, updatedAt: selectedKeys[0] },
								sorter
							);
						}}
						style={{ width: "100%", marginBottom: 8 }}
					>
						Применить
					</Button>
					<Button
						onClick={() => {
							clearFilters();
							fetchLeads(
								pagination,
								{ ...filters, updatedAt: undefined },
								sorter
							);
						}}
						style={{ width: "100%" }}
					>
						Сбросить
					</Button>
				</div>
			),
		},
		{
			title: "Комментарий",
			dataIndex: "comment",
			key: "comment",
			...getColumnSearchProps("comment"),
		},
		{
			title: "Действия",
			key: "actions",
			render: (_, record) => (
				<Button onClick={() => showEditModal(record)}>Редактировать</Button>
			),
		},
	];

	return (
		<div>
			<h2>Лиды</h2>
			<Table
				dataSource={leads}
				columns={columns}
				rowKey="id"
				loading={isLoading}
				pagination={{
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: pagination.total,
					onChange: (page, pageSize) => {
						const newPagination = { current: page, pageSize };
						setPagination(newPagination);
						fetchLeads(newPagination, filters, sorter); // Перезапрашиваем данные
					},
				}}
				onChange={(newPagination, newFilters, newSorter) => {
					const updatedFilters = {
						...filters,
						...newFilters,
					};

					// Обрабатываем диапазон дат, если он есть
					if (newFilters.updatedAt && Array.isArray(newFilters.updatedAt)) {
						updatedFilters.updatedAt = newFilters.updatedAt.join(",");
					}

					setFilters(updatedFilters); // Обновляем фильтры
					setSorter(newSorter); // Обновляем сортировку
					fetchLeads(newPagination, updatedFilters, newSorter); // Отправляем запрос с обновлёнными параметрами
				}}
			/>

			<Modal
				title="Редактировать Лид"
				open={isModalVisible}
				onCancel={handleCancel}
				footer={null}
				width="100%" // Устанавливаем ширину на 100%
				style={{ top: 20 }} // Смещаем модальное окно вниз
			>
				<Form layout="vertical" form={form} onFinish={handleUpdate}>
					<div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
						<Form.Item
							name="name"
							label="Имя"
							rules={[{ required: true }]}
							style={{ flex: "1 1 30%" }}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="phone"
							label="Телефон"
							rules={[{ required: true }]}
							style={{ flex: "1 1 30%" }}
						>
							<Input />
						</Form.Item>
						<Form.Item name="status" label="Статус" style={{ flex: "1 1 30%" }}>
							<Select>
								{statusMap.map((status) => (
									<Option key={status.id} value={status.id}>
										{status.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						{/* Условное отображение для ролей, кроме "employee" */}
						{user.role !== "employee" && (
							<>
								<Form.Item
									name="team"
									label="Команда"
									style={{ flex: "1 1 30%" }}
								>
									<Select>
										{teamOptions.map((team) => (
											<Option key={team.name} value={team.name}>
												{team.name}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									name="assignedTo"
									label="Ответственный сотрудник"
									style={{ flex: "1 1 30%" }}
								>
									<Select
										showSearch
										placeholder="Выберите или введите имя"
										optionFilterProp="children"
										filterOption={(input, option) =>
											option?.children
												.toLowerCase()
												.includes(input.toLowerCase())
										}
									>
										{employeeOptions.map((employee) => (
											<Option key={employee.username} value={employee.username}>
												{employee.username}
											</Option>
										))}
									</Select>
								</Form.Item>
							</>
						)}
						<Form.Item
							name="dateOfBirth"
							label="Дата рождения"
							style={{ flex: "1 1 30%" }}
						>
							<DatePicker format="DD.MM.YYYY" />
						</Form.Item>
						<Form.Item name="app" label="АПП" style={{ flex: "1 1 30%" }}>
							<Input />
						</Form.Item>
						<Form.Item name="bank" label="БАНК" style={{ flex: "1 1 30%" }}>
							<Input />
						</Form.Item>
						<Form.Item
							name="operation"
							label="ОПЕРАЦИЯ"
							style={{ flex: "1 1 30%" }}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="balance"
							label="БАЛАНС"
							style={{ flex: "1 1 30%" }}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="currency"
							label="ВАЛЮТКА"
							style={{ flex: "1 1 30%" }}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="comment"
							label="Комментарий"
							style={{ flex: "1 1 100%" }}
						>
							<Input.TextArea rows={4} />
						</Form.Item>
					</div>
					<Button type="primary" htmlType="submit" block loading={isSubmitting}>
						Сохранить
					</Button>
				</Form>
			</Modal>
		</div>
	);
};

export default LeadList;
