import React, { useState, useEffect } from "react";
import {
	DatePicker,
	Card,
	Statistic,
	Row,
	Col,
	Select,
	message,
	List,
	Spin,
} from "antd";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { getUsers, getUserStatistics } from "../../services/apiMethods";

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const LeadStatistics = ({ user }) => {
	const [statistics, setStatistics] = useState({
		statusCounts: [],
		totalLeads: 0,
	});
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedUser, setSelectedUser] = useState("");
	const [users, setUsers] = useState([]);
	const [loadingUsers, setLoadingUsers] = useState(true);
	const [loadingStats, setLoadingStats] = useState(false);

	useEffect(() => {
		fetchUsers();
	}, []);

	useEffect(() => {
		if (selectedUser) {
			fetchStatistics();
		}
	}, [startDate, endDate, selectedUser]);

	const fetchUsers = async () => {
		setLoadingUsers(true);
		try {
			const allUsers = await getUsers();

			// Фильтровать пользователей для клоузера
			const filteredUsers =
				user.role === "closer"
					? allUsers.filter((u) => u.teamId === user.teamId)
					: allUsers;

			const formattedUsers = filteredUsers.map((u) => ({
				username: u.username,
				id: u.id,
			}));

			setUsers(formattedUsers);

			// Установить первого пользователя по умолчанию
			if (formattedUsers.length > 0) {
				setSelectedUser(formattedUsers[0].username);
			}
		} catch (error) {
			message.error("Ошибка при загрузке списка пользователей");
		} finally {
			setLoadingUsers(false);
		}
	};

	const fetchStatistics = async () => {
		if (!startDate || !endDate) {
			message.warning("Пожалуйста, выберите обе даты для загрузки статистики.");
			return;
		}

		setLoadingStats(true);
		try {
			const stats = await getUserStatistics({
				startDate: startDate.format("YYYY-MM-DD"),
				endDate: endDate.format("YYYY-MM-DD"),
				username: selectedUser,
			});
			setStatistics(stats);
		} catch (error) {
			message.error("Ошибка при загрузке статистики пользователя");
		} finally {
			setLoadingStats(false);
		}
	};

	const data = {
		labels: statistics.statusCounts.map(
			(item) => item.status || "Неизвестный статус"
		),
		datasets: [
			{
				data: statistics.statusCounts.map((item) => item.count),
				backgroundColor: [
					"#FF6384",
					"#36A2EB",
					"#FFCE56",
					"#AAFF56",
					"#FF9AA2",
					"#B39CD0",
					"#FDFD96",
					"#77DD77",
					"#AEC6CF",
					"#CFCFC4",
					"#FFB347",
				],
			},
		],
	};

	return (
		<div>
			<h2>Статистика по лидам пользователя</h2>
			<Row gutter={16} style={{ marginBottom: 20 }}>
				<Col span={8}>
					{loadingUsers ? (
						<Spin size="small" />
					) : (
						<Select
							value={selectedUser}
							onChange={setSelectedUser}
							style={{ width: "100%" }}
							showSearch
							placeholder="Выберите пользователя"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option?.children?.toLowerCase().includes(input.toLowerCase())
							}
						>
							{users.map((u) => (
								<Option key={u.id} value={u.username}>
									{u.username}
								</Option>
							))}
						</Select>
					)}
				</Col>
				<Col span={8}>
					<Row gutter={8}>
						<Col span={12}>
							<DatePicker
								value={startDate}
								onChange={setStartDate}
								style={{ width: "100%" }}
								placeholder="Начальная дата"
								format="DD.MM.YYYY"
							/>
						</Col>
						<Col span={12}>
							<DatePicker
								value={endDate}
								onChange={setEndDate}
								style={{ width: "100%" }}
								placeholder="Конечная дата"
								format="DD.MM.YYYY"
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			{loadingStats ? (
				<Spin size="large" />
			) : (
				<Row gutter={16}>
					<Col span={12}>
						<Card title="Общая статистика">
							<Statistic title="Всего лидов" value={statistics.totalLeads} />
							<List
								header={<b>Подробная статистика по статусам:</b>}
								dataSource={statistics.statusCounts}
								renderItem={(item) => (
									<List.Item>
										{item.status}: {item.count}
									</List.Item>
								)}
							/>
						</Card>
					</Col>
					<Col span={12}>
						<Pie data={data} />
					</Col>
				</Row>
			)}
		</div>
	);
};

export default LeadStatistics;
