import React, { useState, useEffect } from "react";
import { Card, Select, Button, message, Table, Typography, Space } from "antd";
import {
	getTeams,
	getActiveDatabaseForTeam,
	setActiveDatabaseForTeam,
	fetchDatabases,
} from "../../services/apiMethods";

import TeamDatabaseCount from './TeamDatabaseCount';

const { Option } = Select;
const { Text } = Typography;

const TeamDatabaseManager = () => {
	const [teams, setTeams] = useState([]);
	const [databases, setDatabases] = useState([]);
	const [activeDatabaseMap, setActiveDatabaseMap] = useState({});
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [selectedDatabase, setSelectedDatabase] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const loadData = async () => {
			try {
				setIsLoading(true);

				// Загрузка списка команд
				const teamData = await getTeams();
				setTeams(teamData);

				// Загрузка доступных баз
				const dbs = await fetchDatabases();
				setDatabases([
					{ key: null, value: "Не выбрана активная база" },
					...dbs.map((db) => ({ key: db, value: db })),
				]);

				// Загрузка активных баз для каждой команды
				const activeDatabases = {};
				for (const team of teamData) {
					const active = await getActiveDatabaseForTeam(team.name); // Используем имя команды
					activeDatabases[team.id] = active.activeSource || null;
				}
				setActiveDatabaseMap(activeDatabases);
			} catch (error) {
				console.error("Ошибка загрузки данных:", error);
				message.error("Ошибка загрузки данных");
			} finally {
				setIsLoading(false);
			}
		};

		loadData();
	}, []);

	const handleTeamChange = (teamId) => {
		setSelectedTeam(teamId);
		setSelectedDatabase(activeDatabaseMap[teamId]);
	};

	const handleSetActiveDatabase = async () => {
		if (!selectedTeam) {
			message.error("Выберите команду");
			return;
		}

		try {
			const teamName = teams.find((team) => team.id === selectedTeam)?.name;

			await setActiveDatabaseForTeam(teamName, selectedDatabase); // Отправляем имя команды
			setActiveDatabaseMap((prev) => ({
				...prev,
				[selectedTeam]: selectedDatabase,
			}));
			message.success(
				selectedDatabase
					? `Активная база для команды установлена: ${selectedDatabase}`
					: "Активная база для команды сброшена"
			);
		} catch (error) {
			console.error("Ошибка при установке активной базы для команды:", error);
			message.error("Ошибка при установке активной базы для команды");
		}
	};

	const columns = [
		{
			title: "Команда",
			dataIndex: "name",
			key: "name",
			render: (name) => <Text strong>{name}</Text>,
		},
		{
			title: "Текущая активная база",
			dataIndex: "id",
			key: "activeDatabase",
			render: (teamId) => (
				<Text>{activeDatabaseMap[teamId] || "Без базы"}</Text>
			),
		},
		{
			title: "Действия",
			key: "actions",
			render: (_, record) => (
				<Space>
					<Button type="link" onClick={() => handleTeamChange(record.id)}>
						Изменить базу
					</Button>
				</Space>
			),
		},
	];

	return (
		<Card title="Управление базами команд" loading={isLoading}>
			<Text strong>
				* Не выбрана активная база - имеется ввиду, что лиды присваиваются из
				общего списка для команды от нового к старому
			</Text>
			<TeamDatabaseCount />
			<Table
				dataSource={teams.map((team) => ({ ...team, key: team.id }))}
				columns={columns}
				pagination={false}
				rowKey="id"
			/>
			<Card style={{ marginTop: 16 }}>
				<Text strong>Выбор базы для команды</Text>
				<Space direction="vertical" style={{ width: "100%", marginTop: 16 }}>
					<Select
						placeholder="Выберите команду"
						value={selectedTeam}
						onChange={handleTeamChange}
						style={{ width: "100%" }}
					>
						{teams.map((team) => (
							<Option key={team.id} value={team.id}>
								{team.name}
							</Option>
						))}
					</Select>
					<Select
						placeholder="Выберите базу данных"
						value={selectedDatabase}
						onChange={setSelectedDatabase}
						style={{ width: "100%" }}
						disabled={!selectedTeam}
					>
						{databases.map((db) => (
							<Option key={db.key} value={db.key}>
								{db.value}
							</Option>
						))}
					</Select>
					<Button
						type="primary"
						onClick={handleSetActiveDatabase}
						disabled={
							!selectedTeam ||
							selectedDatabase === activeDatabaseMap[selectedTeam]
						}
					>
						Установить активную базу
					</Button>
				</Space>
			</Card>
		</Card>
	);
};

export default TeamDatabaseManager;
