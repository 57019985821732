import axios from 'axios';
import authService from './authService';
import { message } from 'antd'; 

const apiService = axios.create({
  baseURL: 'https://backend.rvutsyatrusiki.com/api',
});

// Перехватчик для добавления JWT-токена
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Удаляем Content-Type, если данные отправляются через FormData
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Перехватчик для обработки ошибок
apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      authService.logout();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Проверка токена
const startTokenCheckInterval = () => {
  const checkAuth = () => {
    if (!authService.isAuthenticated()) { // Проверка через новый метод
      authService.logout();
      message.warning('Сессия истекла. Пожалуйста, войдите снова.');
      window.location.href = '/login';
    }
  };

  setInterval(checkAuth, 60000); // Проверка каждую минуту
};

startTokenCheckInterval();

export default apiService;
