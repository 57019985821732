import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { getWhitelistedIps, addWhitelistedIp, deleteWhitelistedIp } from '../../services/apiMethods';

const WhitelistManagement = () => {
  const [ips, setIps] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchIps();
  }, []);

  const fetchIps = async () => {
    try {
      const data = await getWhitelistedIps();
      setIps(data);
    } catch {
      message.error('Ошибка при загрузке IP-адресов');
    }
  };

  const handleAddIp = async (values) => {
    try {
      await addWhitelistedIp(values);
      message.success('IP успешно добавлен в вайтлист');
      setIsModalVisible(false);
      fetchIps();
    } catch {
      message.error('Ошибка при добавлении IP в вайтлист');
    }
  };

  const handleDeleteIp = async (id) => {
    try {
      await deleteWhitelistedIp(id);
      message.success('IP удалён из вайтлиста');
      fetchIps();
    } catch {
      message.error('Ошибка при удалении IP');
    }
  };

  return (
    <div>
      <h2>Управление IP-вайтлистом</h2>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Добавить IP
      </Button>
      <Table
        dataSource={ips}
        rowKey="id"
        columns={[
          { title: 'IP адрес', dataIndex: 'ipAddress', key: 'ipAddress' },
          {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (
              <Button type="link" onClick={() => handleDeleteIp(record.id)}>
                Удалить
              </Button>
            ),
          },
        ]}
      />
      <Modal
        title="Добавить IP"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleAddIp}>
          <Form.Item
            name="ipAddress"
            rules={[{ required: true, message: 'Пожалуйста, введите IP-адрес' }]}
          >
            <Input placeholder="Введите IP-адрес" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Добавить
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default WhitelistManagement;
