import React, { useState, useEffect } from "react";
import {
	Card,
	Row,
	Col,
	Select,
	DatePicker,
	Button,
	message,
	Spin,
} from "antd";
import { Pie } from "react-chartjs-2";
import {
	getTeams,
	getTeamStatistics,
	getCurrentUser,
} from "../../services/apiMethods";
import moment from "moment";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const ALL_STATUSES = [
	"Новый",
	"Ребенок",
	"НБТ",
	"Нет средств",
	"Умник",
	"Срез",
	"Перезвон",
	"Поляк",
	"Списали",
	"Срез на закрытии",
	"Закрыт",
];

const STATUS_COLORS = {
	Новый: "#FF6384",
	Ребенок: "#36A2EB",
	НБТ: "#FFCE56",
	"Нет средств": "#AAFF56",
	Умник: "#FF9AA2",
	Срез: "#B39CD0",
	Перезвон: "#FDFD96",
	Поляк: "#77DD77",
	Списали: "#AEC6CF",
	"Срез на закрытии": "#CFCFC4",
	Закрыт: "#FFB347",
};

const getStatusColor = (status) => STATUS_COLORS[status] || "#CCCCCC";

const TeamStatistics = ({ user }) => {
	const [teamStats, setTeamStats] = useState([]);
	const [teams, setTeams] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loadingTeams, setLoadingTeams] = useState(true);
	const [loadingStats, setLoadingStats] = useState(false);

	useEffect(() => {
		fetchUserAndTeams();
	}, []);

	useEffect(() => {
		if (selectedTeam) {
			fetchTeamStatistics();
		}
	}, [selectedTeam, startDate, endDate]);

  const fetchUserAndTeams = async () => {
    setLoadingTeams(true);
    try {
      // 1. Загружаем пользователя из localStorage
      const storedUser = JSON.parse(localStorage.getItem("user"));
      if (!storedUser) {
        console.error("Пользователь отсутствует в localStorage.");
        message.error("Не удалось загрузить данные пользователя.");
        return;
      }
      console.log("Загружены данные пользователя из localStorage:", storedUser);
  
      // 2. Получаем все команды
      const allTeams = await getTeams();
      console.log("Все команды:", allTeams);
  
      // 3. Если это клоузер, фильтруем по его teamId
      const filteredTeams =
        storedUser.role === "closer"
          ? allTeams.filter((team) => team.id === storedUser.teamId)
          : allTeams;
  
      console.log("Отфильтрованные команды для текущего пользователя:", filteredTeams);
  
      const formattedTeams = filteredTeams.map((team) => ({
        id: team.id,
        name: team.name,
      }));
      setTeams(formattedTeams);
  
      // 4. Устанавливаем выбранную команду
      if (storedUser.role === "closer" && storedUser.teamId) {
        const userTeam = allTeams.find((team) => team.id === storedUser.teamId);
        if (userTeam) {
          setSelectedTeam(userTeam.name); // Устанавливаем название команды
          console.log("Команда клоузера установлена:", userTeam.name);
        } else {
          console.error("Команда пользователя отсутствует в списке команд.");
          message.error("Команда пользователя отсутствует в списке команд.");
        }
      } else if (formattedTeams.length > 0) {
        setSelectedTeam(formattedTeams[0].name); // Устанавливаем первую команду по умолчанию
        console.log("Установлена команда по умолчанию:", formattedTeams[0].name);
      } else {
        setSelectedTeam(null); // Если команд нет, устанавливаем null
      }
    } catch (error) {
      console.error("Ошибка при загрузке команд:", error);
      message.error("Ошибка при загрузке списка команд");
    } finally {
      setLoadingTeams(false);
    }
  };
  

	const fetchTeamStatistics = async () => {
		if (!startDate || !endDate) {
			message.warning("Пожалуйста, выберите обе даты для загрузки статистики.");
			return;
		}

		if (!selectedTeam) {
			console.error("Не выбрана команда для запроса статистики.");
			message.warning("Команда не выбрана.");
			return;
		}

		setLoadingStats(true);
		try {
			const stats = await getTeamStatistics(
				selectedTeam, // Используем имя команды
				startDate.format("YYYY-MM-DD"),
				endDate.format("YYYY-MM-DD")
			);

			const completeStats = ALL_STATUSES.map((status) => {
				const existingStatus = stats.statusCounts.find(
					(s) => s.status === status
				);
				return {
					status,
					count: existingStatus ? existingStatus.count : 0,
				};
			});

			setTeamStats(completeStats);
		} catch (error) {
			console.error("Ошибка при загрузке статистики команды:", error);
			message.error("Ошибка при загрузке статистики команды");
		} finally {
			setLoadingStats(false);
		}
	};

	const filteredStats = teamStats.filter((stat) => stat.status !== "Новый");

	const totalLeads = filteredStats.reduce((sum, stat) => sum + stat.count, 0);

	const data = {
		labels: filteredStats.map((item) => item.status || "Неизвестный статус"),
		datasets: [
			{
				data: filteredStats.map((item) => item.count),
				backgroundColor: filteredStats.map((item) =>
					getStatusColor(item.status)
				),
			},
		],
	};

	return (
		<div>
			<h2>Статистика по командам</h2>
			<Row gutter={16} style={{ marginBottom: 20 }}>
				<Col span={8}>
					{loadingTeams ? (
						<Spin size="small" />
					) : (
						<Select
							value={selectedTeam || ""}
							onChange={(value) => setSelectedTeam(value)}
							style={{ width: "100%" }}
							showSearch
							placeholder="Выберите команду"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option?.children?.toLowerCase().includes(input.toLowerCase())
							}
						>
							{teams.map((team) => (
								<Option key={team.id} value={team.name}>
									{team.name}
								</Option>
							))}
						</Select>
					)}
					{user.role === "closer" && selectedTeam && (
						<p style={{ marginTop: 8, color: "#888" }}>
							Выбранная команда: <strong>{selectedTeam}</strong>
						</p>
					)}
				</Col>
				<Col span={8}>
					<Row gutter={8}>
						<Col span={12}>
							<DatePicker
								value={startDate}
								onChange={setStartDate}
								style={{ width: "100%" }}
								placeholder="Начальная дата"
								format="DD.MM.YYYY"
							/>
						</Col>
						<Col span={12}>
							<DatePicker
								value={endDate}
								onChange={setEndDate}
								style={{ width: "100%" }}
								placeholder="Конечная дата"
								format="DD.MM.YYYY"
							/>
						</Col>
					</Row>
				</Col>
				<Col span={8}>
					<Button
						type="primary"
						onClick={fetchTeamStatistics}
						style={{ width: "100%" }}
						loading={loadingStats}
					>
						Получить статистику
					</Button>
				</Col>
			</Row>

			{loadingStats ? (
				<Spin size="large" />
			) : (
				<Row gutter={16}>
					<Col span={12}>
						<Card title="Общая статистика">
							<p>Прозвоненные: {totalLeads} лидов</p>
							{filteredStats.map((stat, index) => (
								<p
									key={index}
									style={{ display: "flex", alignItems: "center" }}
								>
									<span
										style={{
											display: "inline-block",
											width: 12,
											height: 12,
											borderRadius: "50%",
											backgroundColor: getStatusColor(stat.status),
											marginRight: 8,
										}}
									></span>
									{stat.status}: {stat.count} (
									{totalLeads > 0
										? ((stat.count / totalLeads) * 100).toFixed(2)
										: 0}
									%)
								</p>
							))}
						</Card>
					</Col>
					<Col span={12}>
						<Card title="Диаграмма статусов">
							<Pie data={data} />
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default TeamStatistics;
