import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import socket from '../../socket'; // Подключаем WebSocket
import './Login.css'; // Подключаем стили

const Login = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // Для отображения галочки при успешном логине
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const userData = await authService.login(values);
      onLogin(userData);
  
      const token = localStorage.getItem('token');
      if (token) {
        socket.emit('authenticate', { token });
      }
  
      setSuccess(true);
      setTimeout(() => {
        switch (userData.role) {
          case 'admin':
            navigate('/admin');
            break;
          case 'closer':
            navigate('/closer');
            break;
          case 'employee':
            navigate('/employee');
            break;
          default:
            navigate('/login');
        }
      }, 1500);
    } catch (error) {
      if (error.message.includes('IP-адрес')) {
        message.error(error.message); // Выводим сообщение с IP
      } else if (error.response?.status === 401) {
        message.error('Неправильный логин или пароль');
      } else {
        message.error('Ошибка сервера');
      }
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="login-container">
      <div className={`logo ${success ? 'success' : ''}`}>{success ? '✔️' : '🔐'}</div>
      <h2>MateCRM Login</h2>
      <Form name="login" onFinish={onFinish} className="login-form">
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Введите имя пользователя' }]}
        >
          <Input placeholder="Имя пользователя" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Введите пароль' }]}
        >
          <Input.Password placeholder="Пароль" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} disabled={success}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
