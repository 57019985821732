import React, { useState, useEffect } from "react";
import { Card, Table, Typography, message } from "antd";
import { getUnassignedCounts } from "../../services/apiMethods"; // Предполагаемый API-метод

const { Text } = Typography;

const TeamDatabaseCount = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const loadUnassignedCounts = async () => {
			try {
				setIsLoading(true);

				// Загружаем данные о базах, командах и остатке лидов
				const unassignedCounts = await getUnassignedCounts(); // Запрос через API
				setData(unassignedCounts); // Обновляем данные в таблице
			} catch (error) {
				console.error("Ошибка загрузки данных:", error);
				message.error("Ошибка загрузки данных");
			} finally {
				setIsLoading(false);
			}
		};

		loadUnassignedCounts();
	}, []);

	const columns = [
		{
			title: "Название базы",
			dataIndex: "source",
			key: "source",
		},
		{
			title: "Команда",
			dataIndex: "team",
			key: "team",
		},
		{
			title: "Остаток лидов",
			dataIndex: "unassignedCount",
			key: "unassignedCount",
			render: (count) => <Text>{count}</Text>,
		},
	];

	return (
		<Card title="Информация о базах и остатке лидов" loading={isLoading}>
			<Table
				dataSource={data.map((item, index) => ({ ...item, key: index }))}
				columns={columns}
				pagination={false}
				rowKey="key"
			/>
		</Card>
	);
};

export default TeamDatabaseCount;
